export default [
  {
    name: 'Margot Wagner',
    title: 'Best Woman',
    description:
      "Becoming fast friends making trouble on a high school field trip to rural Iowa, Margot and Erik continued to wreak havoc amongst their classmates the rest of high school. Margot set up Erik's dating profile that got Katie's attention and helped with the proposal, so it's only fitting that she'll be a part of the big day.",
    image: 'margot-wagner.jpg',
  },
  {
    name: 'Brandon Smith',
    title: 'Groomsman',
    description:
      'Neighbors freshman year, Brandon and Erik have been friends ever since. Many years living together, including two years above a strip club, cringeworthy dates, and nights of mischief have continued to grow the bond over the years.',
    image: 'brandon-smith.jpg',
  },
  {
    name: 'Chris Dineff',
    title: 'Groomsman',
    description:
      "First meeting at three years old when Chris destroyed Erik's block tower at summer camp, they were placed in the same first grade class and were sworn enemies. A third grade school project, however, was the beginning of a great friendship. There hasn't been a dull moment since, with weekends at music festivals, impromptu adventures in Chicago, and other places you might never expect to find yourself, Chris always has the next surprise planned.",
    image: 'chris-dineff.jpg',
  },
  {
    name: 'Pat Walsh',
    title: 'Groomsman',
    description:
      "Baseball teammates at six years old (though neither remembered until many years later), Pat and Erik's friendship grew during high school where they spent many Friday nights staying out of trouble and eating copious amounts of Portillo's and Chipotle. Erik got to be a part of Pat's engagement and now Pat is returning the favor.",
    image: 'pat-walsh.jpg',
  },
  {
    name: 'Adam Prather',
    title: 'Groomsman',
    description:
      'Adam and Erik took many adventures together all over the country while working for the Georgia Tech basketball team, during which Adam always managed to get them lost (no matter what he says). Adam is always going above and beyond for his friends and no wedding would be complete without him.',
    image: 'adam-prather.jpg',
  },
  {
    name: 'Charlie Reynolds',
    title: 'Groomsman',
    description:
      'Charlie may be the brother of the bride, but he and Erik really bonded during lockdown, avoiding responsibility, cultivating business ventures, and talking basketball together.',
    image: 'charlie-reynolds.jpg',
  },
]
