

























import { Component, Vue } from 'vue-property-decorator'
import Main from '@/layouts/Main.vue'
import app from '@/store/modules/app'
import { WeddingPartyMember } from '@/models/WeddingParty'
import WeddingPartyMemberProfile from '@/components/WeddingPartyMemberProfile.vue'
import bridesmaids from '@/data/bridesmaids'
import groomsmen from '@/data/groomsmen'

@Component({
  components: { Main, WeddingPartyMemberProfile },
  metaInfo() {
    return {
      title: `${app.title} | Wedding Party`,
      meta: [
        {
          name: 'description',
          content:
            'Meet the bridesmaids and groomsmen who are part of the wedding party.',
        },
        {
          name: 'keywords',
          content: 'wedding, wedding party, bridesmaids, groomsmen',
        },
        {
          property: 'og:title',
          content: `${app.title} | Wedding Party`,
        },
        {
          property: 'og:description',
          content:
            'Meet the bridesmaids and groomsmen who are part of the wedding party.',
        },
        {
          property: 'og:type',
          content: 'website',
        },
        {
          property: 'og:url',
          content: 'https://themadays.com/wedding-party',
        },
        {
          property: 'og:image',
          content: 'summary_large_image',
        },
      ],
      link: [{ rel: 'canonical', href: 'https://themadays.com/wedding-party' }],
    }
  },
})
export default class WeddingParty extends Vue {
  bridesmaids: WeddingPartyMember[] = bridesmaids
  groomsmen: WeddingPartyMember[] = groomsmen

  get weddingPartyMembers(): WeddingPartyMember[] {
    if (this.$vuetify.breakpoint.xs) {
      return [...this.bridesmaids, ...this.groomsmen]
    }

    const alternating = []
    for (let i = 0; i < bridesmaids.length; i++) {
      alternating.push(bridesmaids[i], groomsmen[i])
    }
    return alternating
  }
}
