export default [
  {
    name: 'Hannah Nettuno-Kintz',
    title: 'Maid of Honor',
    description:
      'Keeping Katie sane (and vice versa) since meeting at a LifeTeen Catholic church retreat a decade ago. Whether it’s a tea date, a hot girl walk, a beach trip, a cabin weekend or anything in between, Hannah’s your gal. Katie was Hannah’s maid of honor for her big day in 2022, and Hannah’s here to return the favor!',
    image: 'hannah-nettuno-kintz.jpg', //beach photos
  },
  {
    name: 'Megan Reynolds',
    title: 'Bridesmaid',
    description:
      'Katie’s favorite sister! Megan has been (not-so-) secretly trying to plan this wedding for the past several years on Pinterest and begging Katie and Erik to get married, so she can’t wait to put her DIY skills to use on the big day.',
    image: 'megan-reynolds.jpg',
  },
  {
    name: 'Ally Zimmerman',
    title: 'Bridesmaid',
    description:
      'Katie’s unexpected roommate-turned-bestie! Ally and Katie have shared everything, from a dorm room, to a friendly ghost, to a love for Father John Misty, to public health classes and study dates, to even family holidays!',
    image: 'ally-zimmerman.jpg',
  },
  {
    name: 'Madison Daniel',
    title: 'Bridesmaid',
    description:
      'Katie’s high school best friend and the only known witness to some questionable choices made by their baddie alter egos. Madison has been by Katie’s side through it all, as they became more responsible but no less fun. Madison and Katie have traded in bonfires for nights out in downtown Alpharetta avoiding high school classmates, and Homecoming/Proms for Katie’s wedding!',
    image: 'madison-daniel.jpg',
  },
  {
    name: 'Mikayla Zeitlin',
    title: 'Bridesmaid',
    description:
      'The closest human to Jess from New Girl that you’ll ever meet – like human sunshine! Mikayla and Katie instantly bonded at Tulane as two girls who just love love! Mikayla also traded dancing on Bourbon St. and sunsets at the Fly for the mountains in Denver. Now, Katie gets a built-in hiking and skiing bestie just on the other side of the Rockies!',
    image: 'mikayla-zeitlin.jpg',
  },
  {
    name: 'Catherine Grayson',
    title: 'Bridesmaid',
    description:
      'Kind, encouraging, fiercely independent, wanderer and entrepreneur! Katie would be nowhere without Catherine’s sage advice and encouragement at Tulane. When Katie gets to tag along on Catherine’s adventures, they lead to rooftop stargazing, hiking, possible UFO sightings in the desert, and planning out future nonprofits that Catherine will no doubt lead one day.',
    image: 'catherine-grayson.jpg',
  },
]
