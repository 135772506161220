









import { Vue, Component, Prop } from 'vue-property-decorator'
import { WeddingPartyMember } from '@/models/WeddingParty'

@Component
export default class WeddingPartyMemberProfile extends Vue {
  @Prop({ type: Object, required: true }) readonly member: WeddingPartyMember

  get name(): string {
    return this.member?.name || null
  }

  get title(): string {
    return this.member?.title || null
  }

  get description(): string {
    return this.member?.description || null
  }

  get image(): string {
    if (!this.member?.image) {
      return null
    }
    try {
      return require(`@/assets/images/weddingParty/${this.member.image}`)
    } catch (e) {
      console.error(e)
    }
    return null
  }
}
